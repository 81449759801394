<template>
  <div id="app">
    <div class="app_container" @scroll="goScroll">
      <div class="container_header">
        <div class="header_logo"></div>
        <div class="header_nav">
          <div
            v-for="(item, index) in navList"
            class="nav_li"
            :class="currentNav == index ? 'nav_li_active' : ''"
            :key="index"
            @click="goNext(item, index)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="container_body">
        <div class="body_banner" id="banner" ref="banner"></div>
        <div class="body_info" id="info" ref="info">
          <div class="info_img"></div>
        </div>
        <div class="body_serve" id="serve" ref="serve"></div>
        <div class="body_friends" id="friends" ref="friends">
          <div class="friends_info"></div>
        </div>
        <div class="body_adress" id="adress" ref="adress"></div>
      </div>
      <div class="container_footer">
        版权所有为：南京海豚数字科技有限公司 苏ICP备2023026461号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      navList: [
        { name: "首页", id: "banner" },
        { name: "关于我们", id: "info" },
        { name: "产品与服务", id: "serve" },
        { name: "合作伙伴", id: "friends" },
        { name: "联系我们", id: "adress" },
      ],
      currentNav: 0,
      scroll: 0,
      banner: 0,
      info: 0,
      isClick: false,
      serve: 0,
      friends: 0,
      adress: 0,
      friendsClient:0,
    };
  },
  methods: {
    goNext(item, index) {
      this.currentNav = index;
      this.isClick = true;
      document.getElementById(item.id).scrollIntoView({
        behavior: "smooth", //定义动画过渡效果"auto"或 "smooth" 之一。默认为 "auto"。
        inline: "start", //"start", "center", "end", 或 "nearest"之一。默认为 "nearest"。
      });
    },
    goScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll <= this.info - 30) {
        this.currentNav = 0;
      }
      if (this.scroll > this.info - 30 && this.scroll <= this.serve - 30) {
        this.currentNav = 1;
      }
      if (this.scroll > this.serve - 30 && this.scroll <= this.friends - 30) {
        this.currentNav = 2;
      }
      if (this.scroll > this.friends - 30 && this.scroll <= this.adress - 30) {
        this.currentNav = 3;
      }
      if (this.scroll > this.adress - this.friendsClient/1) {
        this.currentNav = 4;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.goScroll, true);

    this.$nextTick(() => {
      this.banner = this.$refs.banner.offsetTop;
      this.info = this.$refs.info.offsetTop;
      this.serve = this.$refs.serve.offsetTop;
      this.friends = this.$refs.friends.offsetTop;
      this.adress = this.$refs.adress.offsetTop;
      this.friendsClient = this.$refs.friends.clientHeight;
      this.goScroll();
    });
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 24rem;
}
.app_container {
  width: 100%;
  height: 100vh;
}
.container_header {
  width: 24rem;
  height: 1.275rem;
  box-sizing: border-box;
  padding: 0 3.6rem 0 4.3375rem;
  display: flex;
  position: fixed;
  top: 0;
  background-color: #fff;
  align-items: center;
}
.header_logo {
  width: 2.225rem;
  height: 0.75rem;
  background-image: url("https://cdn.qqq1111.top/images/guanwang/index_logo.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-right: 4.025rem;
}
.header_nav {
  width: 11.25rem;
  height: 0.375rem;
  display: flex;
  font-size: 0.275rem;
  zoom: 1;
}
.nav_li {
  padding-right: 0.9375rem;
  cursor: pointer;
  color: #333333;
}
.nav_li_active {
  color: #00a6e9;
}
.nav_li:hover {
  color: #00a6e9;
}
.container_body {
  margin-top: 1.275rem;
}
.body_banner {
  width: 100%;
  height: 9.975rem;
  background-image: url("https://cdn.qqq1111.top/images/guanwang/index_banner.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.body_info {
  width: 100%;
  height: 8.025rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info_img {
  width: 15.15rem;
  height: 5.675rem;
  background-image: url("https://cdn.qqq1111.top/images/guanwang/index_info.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.body_serve {
  width: 100%;
  height: 15.45rem;
  background-image: url("https://cdn.qqq1111.top/images/guanwang/index_serve.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.body_friends {
  width: 100%;
  height: 7.9875rem;
  background-image: url("https://cdn.qqq1111.top/images/guanwang/index_friends.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.friends_info {
  margin: 1.35rem auto;
  width: 14.9875rem;
  height: 4.0625rem;
  background-image: url("https://cdn.qqq1111.top/images/guanwang/index_friends_info.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.body_adress {
  width: 100%;
  height: 5.4625rem;
  background-image: url("https://cdn.qqq1111.top/images/guanwang/index_address.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #2295ea;
}
.container_footer {
  height: 1.3375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #072143;
  color: #dcdcdc;
  font-size: 0.175rem;
}
</style>
